<template>
    <v-tooltip v-if="showTooltip" :text="tooltipText" :location="tooltipLocation">
        <template v-slot:activator="{ props }">
            <v-btn
                @click="onClick"
                :class="buttonStyle"
                :variant="variant"
                :size="size"
                :density="density"
                :block="block"
                :disabled="disabled"
                :icon="icon"
                :prepend-icon="prependIcon"
                v-bind="props"
                :height="height"
                :width="width"
            >
                <v-icon v-if="icon">{{ icon }}</v-icon>
                <span v-else>{{ text }}</span>
            </v-btn>
        </template>
    </v-tooltip>
    <template v-else>
        <v-btn
            @click="onClick"
            :class="buttonStyle"
            :variant="variant"
            :size="size"
            :density="density"
            :block="block"
            :disabled="disabled"
            :icon="icon"
            :height="height"
            :width="width"
            :prepend-icon="prependIcon"
        >
            <v-icon v-if="icon">{{ icon }}</v-icon>
            <span v-else>{{ text }}</span>
        </v-btn>
    </template>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        buttonStyle: {
            type: String,
            default: "moe-button",
        },
        variant: {
            type: String,
            default: "elevated",
        },
        text: {
            type: String,
            default: "",
        },
        size: {
            type: String,
            default: "default",
        },
        density: {
            type: String,
            default: "default",
        },
        block: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
        },
        prependIcon: {
            type: String,
        },
        showTooltip: {
            type: Boolean,
            default: false,
        },
        tooltipText: {
            type: String,
            default: "",
        },
        tooltipLocation: {
            type: String,
            default: "end",
        },
        height: {
            type: String,
        },
        width: {
            type: String,
            default: "auto",
        },
    },

    methods: {
        onClick() {
            this.$emit('click');
        },
    },
    mounted() {
        // Component mounted
    },
};

</script>

<style scoped>
    /* @import '../assets/styles/main.scss'; */
</style>