import { Tell } from './Tell';

export class BillingCenterService {
    constructor(tell: Tell) {
      BillingCenterService.errorHandler = BillingCenterService.errorHandler.bind(BillingCenterService);
    }
  
    static async getPolicyEvaluation(policyNumber: string) {
      const url = `${process.env.VUE_APP_BILLING_CENTER_API_URL}?PolicyNumber=${policyNumber}`;
  
      try {
        const response = await fetch(url,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
  
        return {
          success: true,
          policyEvaluation: result.getPolicyEvaluationResponse.return
        };
      } catch (error) {
        return this.errorHandler(error);
      }
    }
  
    static errorHandler(error: any): BillingCenterResult {
      if (error.message.includes('404')) {
        return {
          success: false,
          policyEvaluation: 'Billing Center is not available at this time. Please try again later.'
        };
      } else if (error.message.includes('500')) {
        return {
          success: false,
          policyEvaluation: 'Policy not available in Billing Center.'
        };
      } else {
        return {
          success: false,
          policyEvaluation: 'Failed'
        };
      }
    }
  }
  
  export class BillingCenterResult {
    success: boolean;
    policyEvaluation: string;
  }
  
  class PolicyEvaluationResult {
    getPolicyEvaluationResponse: GetPolicyEvaluationResponse;
  }
  
  class GetPolicyEvaluationResponse {
    return: string;
  }