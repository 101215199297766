const onBaseApiUrl = process.env.VUE_APP_ONBASE_API_URL;
import { ErrorService } from "./ErrorService";

export const DocRepoService = {
  async postDocument(docType: string, data: string, fileExtension: string, keywords: any) {
    const onBaseDocsUrl = `${onBaseApiUrl}/OnBaseDocumentServiceREST/api/v1/documents/`;

    const documentData = {
      document: {
        id: docType,
        keywords: keywords,
        content: {
          extension: fileExtension,
          data: data
        }
      }
    };

      const response = await fetch(onBaseDocsUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(documentData)
      });

      if (response === undefined) {
        console.log('post response', response);
        ErrorService(response, 'postDocument');
      }else{
          return await response.json();
      }

  },

  async getDocument(docTypeGroup: string, docType: string, keywords: Array<[string, string]>, getLatest: boolean) {
    const slimKeywords = keywords.map((kw) => encodeURIComponent(`${kw[0]}:${kw[1]}`));
    let onBaseDocsUrl = `${onBaseApiUrl}/OnBaseDocumentServiceREST/api/v1/documents?keywords=${slimKeywords.join()}&documenttypegroups=${encodeURIComponent(docTypeGroup)}&documenttypes=${encodeURIComponent(docType)}`;

    if (getLatest) {
      onBaseDocsUrl += "&orderby=DocumentDate%3adesc&pagesize=1";
    }

      const response = await fetch(onBaseDocsUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        ErrorService(response, 'getDocument');
      }else{
          return await response.json();
      }
  }
};