import { createApp } from 'vue'
import App from './App.vue'
import { router } from '@/services/router'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { Tell } from '@/services/Tell'
import { EntraAuthService } from '@/services/EntraAuthService'
import { CommercialApi } from '@/services/CommercialApi'
import { EntraAuthConfig as entraAuthConfig } from '@/services/EntraAuthConfig'
import { CommercialApiConfig as commercialApiConfig } from '@/services/CommercialApiConfig'
import telemetry from '@/services/telemetry'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import 'bootstrap/dist/css/bootstrap.css'
import './assets/styles/main.scss'
import 'vuetify/styles'
import "@mdi/font/css/materialdesignicons.css";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import { GoogleMap } from 'vue3-google-map';


// services 


// stores 

// styles

const app = createApp(App);
const pinia = createPinia();

pinia.use(piniaPluginPersistedstate);

app.use(router);
app.use(pinia);

// ToDo: is this working? Different color for different environments?
const vuetify = createVuetify({
	components,
	directives,
	icons: {
		defaultSet: 'mdi',
		aliases,
		sets: {
			mdi,
		},
	},
	theme: {
		defaultTheme: "light"
		// themes: {
		//     dev: {
		//         primary: '#4d7e5a',
		//         secondary: '#6099ac',
		//         accent: '#d67c3b',
		//         error: '#c94241',
		//         warning: '#d67c3b',
		//         info: '#80b9cd',
		//         success: '#4d7e5a'
		//     },
		//     acc: {
		//         primary: '#4d7e5a',
		//         secondary: '#6099ac',
		//         accent: '#d67c3b',
		//         error: '#c94241',
		//         warning: '#d67c3b',
		//         info: '#80b9cd',
		//         success: '#4d7e5a'
		//     },
		//     prd: {
		//         primary: '#4d7e5a',
		//         secondary: '#6099ac',
		//         accent: '#d67c3b',
		//         error: '#c94241',
		//         warning: '#d67c3b',
		//         info: '#80b9cd',
		//         success: '#4d7e5a'
		//     }
		// }
	}
});

app.use(vuetify);

app.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
		libraries: ['places'],
	}
});

app.component('GoogleMap', GoogleMap);

// Provide services for injection
// these services are available using the 'inject' function inside your components
app.provide('auth', new EntraAuthService(entraAuthConfig));
app.provide('telemetry', telemetry);
app.provide('tell', new Tell());

const commercialApi = new CommercialApi(commercialApiConfig)

app.provide('policyService', commercialApi);
app.provide('rateService', commercialApi);

app.mount('#app');