export class PolicyState {
	static readonly Verified = "V";
	static readonly Pending = "P";
}

export class NotATuple {
	constructor(public item1: string, public item2: string) { }
}

export class DeductibleViewModel {
	coverageCode?: string;
	status?: string = "V";  // default value as indicated
	state?: string;
	deductibleCode?: string;
	deductibleCodeDescription?: string;
	insuranceLineCode?: string;
}

export class CoverageGroupViewModel {
	coverageCode: string;
	coverageCodeDescription: string;
	deductibles: DeductibleViewModel[] = [];
}

export class InsuranceLineGroupedViewModel {
	state: string;
	insuranceLineCode: string;
	insuranceLineDescription: string;
	coverageCodeDescription: string;
	coverageCode: string;
	coverageGroups: CoverageGroupViewModel[] = [];
}

export class BulkDeductibleViewModel {
	policyNumber?: string;
	symbol?: string;
	module?: string;
	status?: string = PolicyState.Pending;
	transactionType?: string;

	get canUpdate(): boolean {
		return this.status === PolicyState.Pending;
	}

	get fullPolicyNumber(): string {
		return `${this.symbol}${this.policyNumber}${this.module}`;
	}

	coverages: NotATuple[] = [];
	states: string[] = [];
	lineItems: InsuranceLineGroupedViewModel[] = [];
	toDeductibles: DeductibleViewModel[] = [];
	insuranceLines: NotATuple[] = [];
}

export class UpdateBulkDeductiblePost {
	policyNumber: string;
	symbol: string;
	module: string;
	state: string;
	updates: UpdateBulkDeductibleLineItem[];

	constructor(init?: Partial<UpdateBulkDeductiblePost>) {
		Object.assign(this, init);
	}
}

export class UpdateBulkDeductibleLineItem {
	insuranceLineCode: string;
	coverageCode: string;
	deductibleCodeFrom: string;
	deductibleCodeTo: string;
}