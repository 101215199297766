<template>
    
    <v-navigation-drawer
      v-model="drawer"
      :rail="rail"
      permanent
      elevation="5"
      color="#d67c3b"
      width="100%"
      class="rail-text"
      @click="rail = false"
    >
        
    <v-list density="compact" class="mt-3 custom-list" nav>
        <v-list-item class="text-start custom-list-item" prepend-icon="mdi-book-open-variant" title="User Guide" value="guide"></v-list-item>
        <v-list-item @click="exportToOnBase()" class="text-start custom-list-item" prepend-icon="mdi-send" title="Send to OnBase" value="onbase"></v-list-item>
        <v-list-item class="text-start custom-list-item" prepend-icon="mdi mdi-chart-line" title="Policy Loss Ratio Report" value="report"></v-list-item>
        <v-list-item class="text-start custom-list-item" prepend-icon="mdi-file-document" title="Most Recent Electronic Worksheet" value="worksheet"></v-list-item>
    </v-list>
    
    <template v-slot:append>
        <v-container>

            <v-row justify="end" v-if="!rail">
                <v-btn
                icon="mdi-chevron-left"
                variant="text"
                @click.stop="rail = !rail"
                ></v-btn>
            </v-row>

            <v-row justify="center">
                <v-btn
                    icon="mdi-chevron-right"
                    variant="text"
                    @click.stop="rail = !rail"
                    v-if="rail"
                    ></v-btn>
            </v-row>
        </v-container>
    </template>
    </v-navigation-drawer>

    <v-container style="width: 90%;" class="d-flex justify-center flex-wrap">

        <v-card
        :elevation="13"
        color="#245e36"
        rounded="xl"
        class="mt-10 card-ht "
        style="width: 100%;"
        >
            <v-img
                style="height: 45%;"
                :src="require('../assets/img/moe-city.png')"
                cover
            ></v-img>
            <!-- <div style="height: 15%;"></div> -->
            <v-row class="align-end justify-end" style="height: 55%;">
                <v-col cols="12" sm="12" md="6"  xs="12">
                    <v-row class="justify-center">
                        <v-col cols="8" sm="8" md="8" xs="2">
                            <v-text-field hide-details="true" label="Policy / renewal quote number and module (FPP123456700)" v-model="text1" variant="solo" width="40px" density="comfortable"></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" md="2" xs="2" class="">
                            <base-button height="48px" width="120px" buttonStyle="moe-button-secondary" @click="importPolicy(text1)" text="Import"></base-button>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12" sm="12" md="6"  xs="12">
                    <v-row class="justify-center">
                        <v-col cols="8" sm="8" md="8"  xs="8">
                            <v-text-field id="addressInput"  hide-details="true" label="Enter an address or latitude, longitude" variant="solo" v-model="text2" width="40px" density="comfortable"></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" md="2"  xs="2">
                            <base-button height="48px" width="120px" buttonStyle="moe-button-secondary" @click="getLocation(text2)" text="Add"></base-button>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
        <v-container class="d-flex justify-center">
            <v-col>
                <v-row justify="center" align="center"  v-if="mapVisible">
                    <v-col cols="12" sm="6" md="4">
                        <GoogleMap
                            api-key="AIzaSyCeDaVPyjqmNXZBlG2H0Uv3UNc41pmgjes"
                            style="width: 100%; height: 400px"
                            :zoom="10"
                            :center="center"
                            :tilt="3" 
                        >
                                <Marker :options="{ position: center }" />
                        </GoogleMap>
                    </v-col>
                </v-row>
                <v-row justify="center" align="center">
                    <v-col cols="12" sm="6" md="4">
                        <div>
                            <p v-if="isWaiting">Waiting...</p>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-container>
    </v-container>

</template>

<script>
import BaseButton from '@/components/BaseButton.vue';
import { getPropertyInfo, GiaResult } from '@/services/PropertyService';
import { CommercialDataService } from '../services/CommercialDataService';
import { GoogleMap, Marker } from 'vue3-google-map';
import { ResultsInput } from '@/models/CommercialDataModel';
// import img from '@/components/img.vue';
export default {
    name: 'ViewWorkbench',
    route: {
        path: '/workbench',
        meta: {
            requiresAuth: true,
            title: 'Workbench'
        }
    },
    data() {
        return {
            giaResult: null,
            text1: '',
            text2: '',
            center: {
                lat: 47.2031139,
                lng: -121.9919582
            },
            mapVisible: false,
            isWaiting: false,
            drawer: true,
            rail: true,
        };
    },
    props: {
        // Add any necessary props here
    },
    // Add any necessary data, methods, or lifecycle hooks here
    components: {
        BaseButton,
        GoogleMap,
        Marker
    }, 

    methods: {
        test() {
            alert("Hello! I am an alert box!!");
        },
        async fetchPropertyInfo(lat, long) {
            const propertyInfo = await getPropertyInfo(lat, long);
            this.giaResult = propertyInfo;
        },
        async importPolicy(policyQuery) {
            this.isWaiting = true;
            console.log('Importing policy:', policyQuery);
            try {
                const res = await CommercialDataService.importPolicy(policyQuery);
                this.center = res[0].location;
                this.isWaiting = false;
                this.mapVisible = true;
            } catch (error) {
                console.error('Error importing policy:', error);
            }
        },
        async getLocation(address){
            this.isWaiting = true;
            try {
                const res = await CommercialDataService.addLocation(address);
                console.log('Location:', res.location);
                this.center = res.location;
                this.isWaiting = false;
                this.mapVisible = true;
            } catch (error) {
                console.error('Error getting location:', error);
            }
        },
        async exportToOnBase(){
            console.log('Exporting to OnBase');
            try {
                await CommercialDataService.exportToOnBase();
            } catch (error) {
                console.error('Error exporting to OnBase:', error);
            }
        },

    },
}
</script>

<style scoped>
/* Add your custom styles here */
.search{
    background-color: #4d7e5a;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.background{
    background-image: url('../assets/img/moe-city.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.card-ht {
  height: 25%;
}

@media (width: 960px) {
  .card-ht {
    height: 50%;
  }
}
</style>



